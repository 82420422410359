html, body, .max{
  height: 100%;
  overflow: hidden;
}

.max{
  --vertical-navigation-expanded-width: 85%;
  min-height: 100vh;
}

.red{
  background-color: red;
}

.table-no-borders{
  --ag-borders: none;
}

.item:hover{
  background-color: #e1e1e6;
}

.hover:hover{
  text-decoration: underline;
}

ul { 
  list-style-type: "‣ ";
 }

 #header-breadcrumb {
  --breadcrumb-arrow-separator-color: white;
  --breadcrumb-item-active--color: '#fafafc';
}

.progressloader { /* to ensure progressloader stays contained inside the page */
  position: absolute;  
  z-index: 995;
}

nav { /* to ensure the sidebar stays on top of the progressloader */
  position: relative;
  z-index: 998; 
}

.header { /* to ensure progress loader stays under the header */
  z-index: 999;
} 

.motif-table .ag-root-wrapper-body { /* min height to ensure the MotifTable filter pop up box doesn't go under the page when there are only a few table rows */
  min-height: 50vh;
}

button {
  --btn-primary-disabled--color: '#fafafc'
}

.flex-container {
  display: flex;
  flex-wrap: nowrap; 
}

.nav-column {
  flex: 0 0 200px; 
  margin-top: 55px;
}

.content-column {
  flex-grow: 1; /* Fill out space that is not covered by nav menu */
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 55px;
  overflow: auto;
  height: calc(100vh - 55px);
}

label {
  color: #EAEAF2 !important; /* ensure label is easier to read in dark mode than the default dark grey font. EY Grey 200 */
}